import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observable, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { omit } from 'lodash'
import {
  AddButton,
  RightDivider,
  SaveButton,
  TargetTextInput,
  TargetCheckbox,
  TargetDatePicker,
  TargetRadioButtons,
  TargetNumberInput,
  TargetSelect,
} from '@code-yellow/spider';
import { Form, Button, Popup, Icon } from 'semantic-ui-react'
import { Body, ContentContainer, Sidebar } from 're-cy-cle'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import BatchTypesTable from 'component/BatchTypesTable'
import ProcessVersionEdit from 'component/ProcessVersionEdit'

// components
import Toolbar from 'component/Toolbar'
import { EmptyMessageContainer } from 'component/AdminOverview'
import { Metavalues, Metafields, MetafieldsProvider } from 'component/Metafields'
// end components

// helpers
import { DATETIME_FORMAT } from 'helpers'
import sortSteps from 'helpers/sortSteps'
import { isFeatureFlagEnabled } from 'helpers/featureFlags'
// end helpers

// stores
import { ArticleType } from 'store/ArticleType'
import { CopyButton, PasteButton } from 'container/ArticleType/CopyPaste'
import { ProductionRequest } from 'store/ProductionRequest'
import { BatchType, LOCAL_TYPES, TYPE_MAKE } from 'store/BatchType'
import { ProcessVersion } from 'store/ProcessVersion'
import { StorageLocationStore } from '../../store/StorageLocation'
import { ClassificationStore } from 'store/Classification'
// end stores

const FullEmptyMessageContainer = styled(EmptyMessageContainer)`
  margin: 0 !important;
  padding: 25px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.2);
`

const SmallTargetDatePicker = styled(TargetDatePicker)`
  > .ui.input > input {
    width: 100% !important;
  }
`

function getStepBatchSizesBase({ branches, steps }, final, productionRequestQuantity) {
  const batchSizes = {}

  let before = null

  // eslint-disable-next-line
  for (const branch of branches) {
    const res = getStepBatchSizesBase(branch, false, productionRequestQuantity)
    Object.assign(batchSizes, res.batchSizes)
    if (res.after !== null && (before === null || res.after < before)) {
      before = res.after
    }
  }

  let lastBatchSizes = null
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i]
    // Check if step creates a new batch
    if (
      step.type === 'split' || // Batch
      i === (['subprocesses', 'multiplier'].includes(steps[0].type) ? 1 : 0) || // Merge or first
      (i > 0 && steps[i - 1].type === 'multiplier') // Multiplier
    ) {
      let after = before
      if (step.type === 'split' && step.splitStep.newBatchVariableUseOrderSize) {
        after = productionRequestQuantity
      } else if (step.type === 'split') {
        after = (step.splitStep.newBatchQuantity || 1)
      }

      lastBatchSizes = { before, after, final: false, syncTo: [], useOrderSize: step.type === 'split' && step.splitStep.newBatchVariableUseOrderSize }
      batchSizes[step.cid] = lastBatchSizes
      before = after
    } else if (lastBatchSizes) {
      lastBatchSizes.syncTo.push(step)
    }
  }

  if (final && lastBatchSizes) {
    lastBatchSizes.final = true
  }

  return { batchSizes, after: before }
}

export function getStepBatchSizes(steps, productionRequestQuantity) {
  const { batchSizes } = getStepBatchSizesBase(steps, true, productionRequestQuantity)
  return batchSizes
}

export function getFakeProductionRequest(articleType) {
  const productionRequest = new ProductionRequest({}, {
    relations: ['articleType', ...articleType.__activeRelations.map((rel) => `articleType.${rel}`)],
  })
  productionRequest.articleType = articleType
  return productionRequest
}

@observer
export default class ArticleTypeEdit extends Component {
  static propTypes = {
    articleType: PropTypes.instanceOf(ArticleType).isRequired,
    batchType: PropTypes.instanceOf(BatchType),
    version: PropTypes.instanceOf(ProcessVersion),

    selectBatchType: PropTypes.func.isRequired,
    addNewBatchType: PropTypes.func.isRequired,

    onClose: PropTypes.func.isRequired,
    machineEnabled: PropTypes.bool,

    view: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    afterSave: PropTypes.func
  }

  static defaultProps = {
    machineEnabled: false,
  }

  constructor(...args) {
    super(...args)
    this.save = this.save.bind(this)
    this.finalize = this.finalize.bind(this)
    this.nextVersion = this.nextVersion.bind(this)
  }

  @observable productionRequest = getFakeProductionRequest(this.props.articleType)
  @observable storageLocationStore = new StorageLocationStore()
  @observable classificationStore = new ClassificationStore()

  componentDidMount() {
    this.storageLocationStore.fetch()
    this.articleTypeReaction = reaction(
      () => this.props.articleType,
      (articleType) => this.productionRequest = getFakeProductionRequest(articleType),
    )
  }

  componentWillUnmount() {
    this.articleTypeReaction()
  }

  async save() {
    const { articleType, batchType, selectBatchType, afterSave } = this.props

    const newTemplate = batchType && batchType.__changes.includes('source')

    if (articleType.isNew) {
      // Save in 2 steps, first article without batch types, then with batch
      // types. This is because a batch type component is auto created, and
      // when you multiput articles with batch types directly, that auto
      // created batch type will be missing from the article.batch_types
      // array posted to the server. This means that the binder thinks it
      // should be removed...
      const basicArticleType = new ArticleType(articleType.toJS(), {
        relations: [
          'batchTypes',
          ...articleType.metavalues.__activeRelations.map((rel) => `metavalues.${rel}`),
        ]
      })
      try {
        await basicArticleType.save({
          onlyChanges: true,
          mapData: (data) => omit(data, 'batch_types'),
          relations: ['metavalues'],
        })
      } catch (e) {
        articleType.__backendValidationErrors = basicArticleType.__backendValidationErrors
        throw e
      } finally {
        articleType.metavalues = basicArticleType.metavalues
      }
      await basicArticleType.fetch()

      runInAction(() => {
        articleType.id = basicArticleType.id
        // eslint-disable-next-line
        for (const batchType of basicArticleType.batchTypes.models) {
          articleType.batchTypes.add(batchType.toJS())
        }
      })
    }

    // We temporarily overwrite toBackendAll so that we can do some extra
    // processing
    const toBackendAll = articleType.toBackendAll
    articleType.toBackendAll = (...args) => {
      const data = toBackendAll.call(articleType, ...args)
      // Collect which steps have changed
      const changedSteps = {}
      // eslint-disable-next-line
      for (const stepData of data.relations.step || []) {
        changedSteps[stepData.id] = stepData
      }
      // Iterate over all process versions
      // eslint-disable-next-line
      for (const batchType of articleType.batchTypes.models) {
        // eslint-disable-next-line
        for (const processVersion of batchType.processVersions.models) {
          // Skip if no steps changed
          if (!processVersion.steps.models.some((step) => changedSteps[step.getInternalId()])) {
            continue
          }
          // Get batch sizes per step
          const stepBatchSizes = getStepBatchSizes(sortSteps(processVersion.steps.models), null)
          // eslint-disable-next-line
          for (const step of processVersion.steps.models) {
            // SKip if no batch sizes
            const batchSizes = stepBatchSizes[step.cid]
            if (!batchSizes) {
              continue
            }
            // Make sure step is included in data
            const stepId = step.getInternalId()
            let stepData = changedSteps[stepId]
            if (stepData === undefined) {
              stepData = { id: stepId }
              data.relations.step.push(stepData)
            }
          }
        }
      }
      return data
    }

    // Just a promise without await first so that the finally block runs
    // immediately after the request is initiated
    let promise
    try {
      promise = articleType.save({
        onlyChanges: true,
        relations: [
          'metafields',
          'metavalues',
          'batchTypes.processVersions.steps.sections.parts.textPart',
          'batchTypes.processVersions.steps.sections.parts.tablePart',
          'batchTypes.processVersions.steps.sections.parts.imagePart',
          'batchTypes.processVersions.steps.sections.parts.metaPart',
          'batchTypes.processVersions.steps.formStep.form.fields.scanConstraints',
          'batchTypes.processVersions.steps.splitStep.form.fields.scanConstraints',
          'batchTypes.processVersions.steps.printStep',
          'batchTypes.processVersions.steps.multiplierStep',
          'batchTypes.processVersions.steps.subprocessesStep',
          'batchTypes.processVersions.steps.byproductStep',
          'batchTypes.processVersions.steps.nestStep',
          'batchTypes.processVersions.steps.exportStep',
          'batchTypes.processVersions.steps.importStep',
          'batchTypes.processVersions.steps.capabilities', // See ticket in github: https://github.com/CodeYellowBV/mobx-spine/issues/86
          ...isFeatureFlagEnabled('carrier_integration') ? [
            'batchTypes.processVersions.steps.carrierStep',
          ] : [],
        ],
      })
    } finally {
      articleType.toBackendAll = toBackendAll
    }

    const res = await promise
    afterSave()

    if (newTemplate) {
      selectBatchType(batchType)
      await batchType.fetch()
    }

    return res
  }

  async finalize() {
    const { version, afterSave } = this.props

    const step = this.step && this.step.id

    await version.finalize()
    await version.fetch()
    this.step = step && version.steps.get(step)
    afterSave()
  }

  async nextVersion() {
    const { version, afterSave, articleType } = this.props

    let step = this.step && this.step.id

    const res = await version.nextVersion()
    step = step && res.meta.step_id_mapping[step]

    // A new process version is created by the backend, and now
    // we do a complete fetch to get that version. The selected
    // batchType is no longer the same one as the fetched one, so
    // we need to update the selected batch type.
    //byproductStep
    // TODO: don't do full refetch, which also most likely will fix
    // the outdated this.selectedBatchType.
    await articleType.fetch()
    const { batchType, selectBatchType } = this.props
    selectBatchType(batchType, null)

    this.step = step && version.steps.get(step)
    afterSave()
  }

  render() {
    const { articleType, batchType, version, addNewBatchType, selectBatchType, view, baseUrl } = this.props

    let popupContent = ''

    if (batchType) {
      if (!version.finalizedAt && !version.updatedAt) {
        popupContent = t('process.edit.noUpdateInfo')
      } else if (version.finalizedAt) {
        popupContent = (
          <>
            <div>{t('process.edit.finalized.label', { date: version.finalizedAt.format(DATETIME_FORMAT) })}</div>
            {!version.finalizedBy.isNew && (
              <div>{t('process.edit.finalized.by', { user: version.finalizedBy.fullName })}</div>)
            }
          </>
        )
      } else {
        popupContent = (
          <>
            {version.updatedAt && (
              <div>{t('process.edit.updated.label', { date: version.updatedAt.format(DATETIME_FORMAT) })}</div>)
            }
            {!version.updatedBy.isNew && (
              <div>{t('process.edit.updated.by', { user: version.updatedBy.fullName })}</div>)
            }
          </>
        )
      }
    }

    return (
      <Body>
        <ContentContainer>
          <Sidebar>
            <Form>
              {!articleType.exactItem.isNew && (
                <TargetTextInput readOnly target={articleType.exactItem} name="code" />
              )}
              <TargetTextInput
                required
                target={articleType.exactItem.isNew ? articleType : articleType.exactItem}
                name="name"
                disabled={!articleType.exactItem.isNew}
              />
              {isFeatureFlagEnabled('warehouse_manager') && (<TargetNumberInput
                label={(<>{t('articleType.field.size.label')}
                <Popup
                  trigger={<Icon style={{ marginBottom:'0.5em' }} color='grey' name="info circle" />}
                  content={t('articleType.field.size.explanation')}
                /></>)}
                allowDecimal={true}
                target={articleType}
                decimalLimit={2}
                name="size"
              />)}
              {isFeatureFlagEnabled('warehouse_manager') && (<TargetSelect remote
                      target={articleType}
                      name="preferredLocations"
                      multiple={true}
                      store={this.storageLocationStore}
                      toOption={(location) => ({
                        value: location.id,
                        text: location.code,
                      })}
                      searchKey=".name:icontains"
                    />)}
              {(articleType.startDate || articleType.endDate) && (
                <Form.Group widths="equal">
                  <SmallTargetDatePicker disabled target={articleType} name="startDate" />
                  <SmallTargetDatePicker disabled target={articleType} name="endDate" />
                </Form.Group>
              )}
              <TargetSelect search remote clearable
                target={articleType}
                name="classification"
                searchKey=".name:istartswith"
                store={this.classificationStore}
                toOption={classification => ({
                  text: classification.name,
                  value: classification.id,
                })}
              />
              <TargetRadioButtons
                target={articleType}
                name="myTrackBatchUsage"
                options={[
                  { text: t('form.no'), value: false },
                  { text: t('form.yes'), value: true },
                ]}
                subLabel={(
                  <Popup hoverable
                    position='bottom right'
                    trigger={!articleType.classification.isNew && (
                      <TargetCheckbox
                        noLabel rightLabel
                        name="followClassification"
                        label={t('articleType.field.trackBatchUsage.followClassificationCheckbox')}
                        value={articleType.myTrackBatchUsage === null}
                        onChange={checked => {
                          articleType.setInput('myTrackBatchUsage', checked ? null : true)
                        }}
                      />
                    )}
                    content={
                      <>
                        <p>{t('articleType.field.trackBatchUsage.followClassificationHelpLine1', { classification: articleType.classification })} "<b>{articleType.classification.name}</b>".</p>
                        <p>{t('articleType.field.trackBatchUsage.followClassificationHelpLine2', { classification: articleType.classification })} "<b>{t(`form.${articleType.classification.trackBatchUsage ? 'yes' : 'no'}`)}".</b></p>
                      </>
                    }
                  />
                )}
              />
              <Metavalues model={articleType} />
              <Metafields model={articleType} />
              <Form.Field>
                <label>{t('articleType.field.batchTypes.label')}</label>
                <BatchTypesTable
                  batchTypeStore={articleType.batchTypes}
                  selectedBatchType={batchType}
                  selectedVersion={version}
                  onSelect={selectBatchType}
                  types={articleType.isMake ? LOCAL_TYPES : LOCAL_TYPES.filter((type) => type !== TYPE_MAKE)}
                />
                <AddButton
                  fluid
                  primary
                  data-test-add-batch-type
                  disabled={articleType.isLoading}
                  icon="add"
                  onClick={addNewBatchType}
                />
              </Form.Field>
            </Form>
          </Sidebar>
          {!batchType ? (
            <FullEmptyMessageContainer>{t('process.edit.noBatchTypeSelected')}</FullEmptyMessageContainer>
          ) : (
            <MetafieldsProvider includeParentLevels model={this.productionRequest}>
              <ProcessVersionEdit
                header={t(`process.${batchType.isNew ? 'create' : 'edit'}.title`)}
                view={view}
                baseUrl={baseUrl + '/'}
                batchType={batchType}
                processVersion={version}
              />
            </MetafieldsProvider>
          )}
        </ContentContainer>
        <Toolbar>
          {batchType && batchType.type !== 'on_the_fly' && !version.isLoading && (
            <>
              <CopyButton compact processVersion={version} />
              {version.draft && <PasteButton compact processVersion={version} />}
            </>
          )}
          <RightDivider />
          {batchType && (
            <React.Fragment>
              <Button.Group compact>
                <Button
                  icon="chevron left"
                  disabled={version.isLoading || version.version === 1}
                  as={Link}
                  to={`/assets/article-type/${articleType.id}/batch-type/${batchType.id}/version/${version.version - 1
                    }/edit/${view}`}
                />
                <Popup
                  trigger={
                    <Button
                      loading={version.isLoading}
                      content={t('process.edit.version', { version: version.version })}
                    />
                  }
                  content={popupContent}
                />
                <Button
                  icon="chevron right"
                  disabled={version.isLoading || version.latest}
                  as={Link}
                  to={
                    version.version + 1 === version.latestVersion
                      ? `/assets/article-type/${articleType.id}/batch-type/${batchType.id}/edit/${view}`
                      : `/assets/article-type/${articleType.id}/batch-type/${batchType.id}/version/${version.version + 1
                      }/edit/${view}`
                  }
                />
              </Button.Group>
              {this.props.view === 'instructions' && (
                <Button data-test-print-all-instructions-button
                  as="a"
                  href={`${version.api.baseUrl.substring(0, version.api.baseUrl.length - 1)}${version.url
                    }print_instructions/`}
                  primary
                  compact
                  loading={version.isLoading}
                  disabled={version.hasUserChanges}
                  icon="check"
                  labelPosition="left"
                  content={'Print All Instructions'}
                  target="_blank"
                />
              )}
              {!version.isLoading && version.draft && (
                <Button
                  primary
                  compact
                  data-test-finalize-button
                  loading={version.isLoading}
                  disabled={version.isNew || version.hasUserChanges}
                  onClick={this.finalize}
                  icon="check"
                  labelPosition="left"
                  content={t('process.edit.finalizeButton')}
                />
              )}
              {!version.isLoading && version.latest && !version.draft && batchType.source.isNew && (
                <Button
                  primary
                  compact
                  data-test-next-version-button
                  loading={version.isLoading}
                  disabled={version.hasUserChanges}
                  onClick={this.nextVersion}
                  icon="add"
                  labelPosition="left"
                  content={t('process.edit.nextVersionButton')}
                />
              )}
            </React.Fragment>
          )}
          <SaveButton primary compact data-test-save-button loading={articleType.isLoading} onClick={this.save} />
        </Toolbar>
      </Body>
    )
  }
}
